import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(
        private toaster: ToastrService,
        private router: Router ,
        private storageservice:StorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse | any) => {
                if (error.status === 401) {
                    this.storageservice.removeAccessToken()
                    this.router.navigateByUrl('/login');
                }
                if (error.status === 302) {
                    this.storageservice.removeAccessToken()
                    this.router.navigateByUrl('/login');
                }
                if (error.status === 422) {
                    const errors = error?.error?.errors;
                    for (const error of errors) {
                        this.toaster.error(`${error.errors.join(' ')}`, "Error");
                    }
                } else if (error.status === 500) {
                    const errors = error?.error?.errors;
                    if (!errors)
                        this.toaster.error(`Internal Server Error`, "Error");
                } else if (error.status === 403) {
                    this.toaster.error(error.error.errors[0].errors[0], error.statusText);
                }
                return throwError(error);
            })
        );
    }
}
