import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { StorageService } from './shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from './shared/services/profile.service';
import { IToken } from './shared/interfaces/token.interface';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Agent Dashboard';

  constructor(
    private oauth: AuthService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) { }

  async ngOnInit() {
    await this.route.queryParams.subscribe(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('scope') !== null) {
        this.router.navigate(['/reset-password'], {
          queryParams: {
            token: urlParams.get('token'),
            scope: urlParams.get('scope'),
            email: urlParams.get('email'),
          },
          replaceUrl: true,
        });
      }
      if (urlParams.get('token') !== null && urlParams.get('scope') === null) {
        const access = {
          access_token: urlParams.get('token'),
          expires_in: Date.now() + 3600 * 1000,
          refresh_token: '',
        };
        const client = {
          access_token: urlParams.get('token'),
          expires_in: Date.now() + 3600 * 1000,
          token_type: 'Bearer',
        };
        localStorage.setItem('ClientAccessToken', JSON.stringify(client));
        localStorage.setItem('AccessToken', JSON.stringify(access));


        // this.profileService.GetProfile().subscribe((res: any) => {
        //   localStorage.setItem('User', JSON.stringify(res.data));
        //   localStorage.setItem('Profile', JSON.stringify(res.data));
        // });
      }
    });
    this.oauth.Token().subscribe((result: IToken) => {
      result.expires_in = Date.now() + result.expires_in * 1000;
      this.storageService.setClientAccessToken(result);
    });
    const expToken:any = this.storageService.getAccessToken();
    if (expToken) {
        if (expToken.expires_in < Date.now()) {
            this.storageService.removeAll();
            this.storageService.removeUserInfo();
            this.router.navigate(['/login']);
            return;
        }
    }

    this.route.queryParams.subscribe((params) => {
      const scope = params['scope'];
      if (scope == 'password-reset') {
        //   this.router.navigate(['/reset-password'], { queryParamsHandling: "merge" });
      }
    });

    const token = this.storageService.getAccessToken();
    if (token && token.expires_in < Date.now()) {
      const data: any = { refresh_token: token.refresh_token };
      this.oauth
        .RefreshToken(data)
        .subscribe((response) => {
          const tokenObject = {} as IToken;
          tokenObject.access_token = response.access_token;
          tokenObject.expires_in = Date.now() + response.expires_in * 1000;
          tokenObject.refresh_token = response.refresh_token;
          this.storageService.removeAccessToken();
          this.storageService.setAccessToken(tokenObject);
          this.storageService.setUserInfo(response.data);
          this.router.navigate([this.router.url], { replaceUrl: true });
        })
        .add(() => {
          window.location.reload();
        });
    }
    // if (token === null) {
    //   this.router.navigate(['/login'])
    // }
    if (environment.production && location.protocol === 'http:') {
      window.location.replace(location.href.replace('http', 'https'));
    } 
  }
}
